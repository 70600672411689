export default {
    name: "card",
    props: {
        item: {
            type: Object,
            default: () => {
            }
        },

    },
    data() {
        return {
            query: {}
        }
    },
    computed: {},
    created() {


        let query = {}

        this.item.filters
            .forEach(filter => {
                if (!(filter.productAttribute.id && filter.attributeValues?.length)) {
                    return
                }

                query[filter.productAttribute.id] = filter.attributeValues
                    .map(({id}) => id)
                    .join(',')
            })

        this.query = query
    },
    methods: {},

}
