import {mapActions, mapGetters, mapMutations} from "vuex";
import card from '@/modules/constructor/components/card/index.vue'

export const constructor = {
    name: "constructor",
    components: {
        card
    },
    data() {
        return {
            complexId: '',
            hoverdBuilding: '',
            selectedBuilding: '',
            hoveredBuildingElement: '',
            selectedBuildingElement: '',
            categoryList: '',
            coords: [],

            radioArr: [],
            coordsArr: []
        }
    },
    watch: {},
    created() {
        this.radioArr = this.coords.map(str => {
            return str.split(',').map((item, index) => {
                if (index % 2 === 0) {
                    return parseInt(item) / 1080 //  Image width
                } else {
                    return parseInt(item) / 616 //  Image height
                }
            })
        })
        // this.adaptiveConstructor()
    },
    mounted() {

        if (this.constructorsData) {
            let complexName = this.$route.name
            let complex = this.constructorsData.find(complex => complex.key === complexName)
            this.complexId = complex.id
        } else {
            this.constructors().then(() => {
                let complexName = this.$route.name
                let complex = this.constructorsData.find(complex => complex.key === complexName)
                this.complexId = complex.id
            })
        }
        this.adaptiveConstructor()

    },
    computed: {
        ...mapGetters({
            constructorsData: 'constructors/constructors',
            buildingElements: 'constructors/buildingElements'
        }),
    },
    methods: {
        adaptiveConstructor() {
            window.onresize = () => {
                const imgWidth = this.$refs.industrialImg.width
                const imgHeight = this.$refs.industrialImg.height
                this.coordsArr = this.radioArr.map(arr => {
                    return arr.map((item, index) => {
                        if (index % 2 === 0) {
                            return Math.round(imgWidth * item)
                        } else {
                            return Math.round(imgHeight * item)
                        }
                    })
                })
            }
            window.onresize()
        },
        getImageTitle(item) {
            if (this.buildingElements) {
                let buildings = this.buildingElements.buildingElements.find(el => el.key === item)
                if (buildings) {
                    return buildings.name ? buildings.name : null
                }

            }
        },
        getCurrentBuildings() {
            if (!this.constructorsData) {
                this.constructors().then(() => {
                    let buildings = this.constructorsData.find(building => building.key === this.$route.name)
                    return buildings.buildings
                })
            } else {
                let buildings = this.constructorsData.find(building => building.key === this.$route.name)
                return buildings.buildings
            }

        },
        getBuildingCategory() {
            this.getBuildingElements()
        },
        scrollTo() {
            const myEl = document.getElementById('selectedBuilding')
            this.$smoothScroll({
                scrollTo: myEl,
            })
        },
        scrollToCategories() {
            const myEl = document.getElementById('selected-categories')
            if (myEl) {
                this.$smoothScroll({
                    offset: -350,
                    scrollTo: myEl,
                })
            }

        },
        showLayer: function (item, build) {

            this.hoverdBuilding = build.key
            let images = Array.from(document.querySelectorAll(`.${item}`))
            images.forEach(img => {
                img.classList.add('activeLayer')
            })
        },
        hideLayer: function (item) {
            this.hoverdBuilding = ''
            let images = Array.from(document.querySelectorAll(`.${item}`))
            images.forEach(img => {
                img.classList.remove('activeLayer')
            })
        },
        showBuildingLayer(hideItem, showItem) {

            if (this.buildingElements) {
                let currentElement = this.buildingElements.buildingElements.find(el => el.key === hideItem)
                if (currentElement && currentElement.image) {
                    if (window.innerWidth <= 550) {
                        this.$refs.selectedImageMobile.classList.add('selectedImageShowMobile')
                        this.$refs.selectedImageMobile.src = null
                        this.$refs.selectedImageMobile.src = currentElement.image
                    } else {
                        this.$refs.selectedImage.classList.add('selectedImageShow')
                        this.$refs.selectedImage.src = null
                        this.$refs.selectedImage.src = currentElement.image
                    }

                }
            }

            this.hoveredBuildingElement = hideItem
            let imagesHide = Array.from(document.querySelectorAll(`.${hideItem}`))
            let imagesShow = Array.from(document.querySelectorAll(`.${showItem}`))
            imagesHide.forEach(img => {
                img.classList.add('hide-layer')
            })
            imagesShow.forEach(img => {
                img.classList.add('activeLayer')
            })
        },
        hideBuildingLayer(hideItem, showItem) {
            if (window.innerWidth <= 550) {
                this.$refs.selectedImageMobile.classList.remove('selectedImageShowMobile')
            } else {
                this.$refs.selectedImage.classList.remove('selectedImageShow')
            }

            this.hoveredBuildingElement = ''
            let imagesHide = Array.from(document.querySelectorAll(`.${hideItem}`))
            let imagesShow = Array.from(document.querySelectorAll(`.${showItem}`))

            imagesHide.forEach(img => {
                img.classList.remove('hide-layer')
                img.classList.remove('showLayer')
            })

            imagesShow.forEach(img => {
                img.classList.remove('activeLayer')
            })
            Array.from(document.querySelectorAll('.elements-layer')).forEach(el => {
                el.classList.remove('hide-element')
            })
            Array.from(document.querySelectorAll('.hovered-layer')).forEach(el => {
                el.classList.remove('showLayer')
            })
        },
        getSelectedArea(item) {
            this.selectedBuilding = item
            this.getBuildingElements({constructorId: this.complexId, buildingId: item.id}).then(() => {
            }).catch(() => {
                console.log()
            })
        },
        getSelectedElements(item) {
            this.selectedBuildingElement = item
            let findElements = this.buildingElements.buildingElements.find(el => el.key === item)
            this.categoryList = findElements.categories
            // this.scrollTo()
            this.scrollToCategories()
        },

        ...mapMutations({}),
        ...mapActions({
            getBuildingElements: 'constructors/GET_BUILDING_ELEMENTS',
            constructors: 'constructors/GET_CONSTRUCTORS',
        })
    }
}
